import { sortBy, sum } from 'lodash'
import { CommercialsGroupedItem, CommercialsItem } from './entities/Commercials'

export const calculateAverageRebateMargin = (
  items: CommercialsItem[] | undefined
): number | undefined => {
  if (items != null) {
    const totalAverageRebate =
      sum(
        items
          .filter((i) => i.averageRebate)
          .map((i) => i.totalQuantity! * i.averageRebate!)
      ) ?? 0
    if (totalAverageRebate === 0) {
      return undefined
    }

    const totalAverageCosts =
      sum(
        items
          .filter((i) => i.averageRebate)
          .map((i) => (i.totalQuantity ?? 0) * (i.averagePrice ?? 0))
      ) ?? 0

    return ((totalAverageRebate - totalAverageCosts) / totalAverageRebate) * 100
  }
  return undefined
}

export const calculateVmpGroupFigures = (
  item: CommercialsGroupedItem
): CommercialsGroupedItem => {
  const amps = item.amps!
  const totalSpent = sum(amps.map((i) => i.totalSpent))
  const totalQuantity = sum(amps.map((i) => i.totalQuantity))
  const hasRebateForEveryDay = amps.every((i) => i.hasRebateForEveryDay)

  const averagePrice = totalSpent / totalQuantity

  let averageRebate: number | undefined = undefined
  let rebateMargin: number | undefined = undefined
  const rebates = amps.map((i) => i.averageRebate).filter((r) => r != null)
  if (rebates.length > 0) {
    averageRebate = sum(rebates) / amps.length
    rebateMargin =
      averageRebate !== 0
        ? ((averageRebate - averagePrice) / averageRebate) * 100
        : undefined
  }

  const cheapestSupplier = sortBy(amps, (i) => i.averagePrice)[0]
    .cheapestSupplier

  return {
    ...item,
    totalQuantity,
    totalSpent,
    averagePrice,
    averageRebate,
    hasRebateForEveryDay,
    rebateMargin,
    cheapestSupplier,
  }
}

export const roundToTwoDecimalPlaces = (value?: number) =>
  value == null ? undefined : parseFloat((value as number).toFixed(2))
