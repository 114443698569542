import { VirtuosoColumnSorting } from '../components/Data/VirtuosoMuiTable'
import { isEqual } from 'lodash'
import { useEffect, useReducer } from 'react'
import {
  RecoilState,
  atom,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil'
import {
  stockrxUserState,
  userAvailableSuppliersState,
} from '../state/AuthenticationState'
import {
  DatePickerSelectionPreset,
  calculateDatesFromPreset,
} from '../components/Forms/DateRangePickers'
import { selectedClientState } from '../state/SelectedPharmacyState'
import { useSuppliersUniquePerId } from '../hooks/useSuppliers'
import { EndlessScrollingArgs } from '../utils/PagedRequestsHelper'
import {
  FilterBarAction,
  FilterBarState,
} from '../components/Interactions/FilterBar'

export interface PageState<T> extends FilterBarState {
  isRestoredCache?: boolean
  getItemId: (item: T) => T[keyof T]
  filterAndSort?: (items: T[], state?: unknown) => T[]

  refreshRandom: number | null
  pageIndex: number
  pageSize?: number
  hasMorePages: boolean
  lastItemIndex: number
  sorting?: VirtuosoColumnSorting | null
  items: T[]
  filteredItems?: T[]
  error: string | null
}

type SetErrorAction = {
  type: 'setError'
  payload: string | null
}

type SetSortingAction = {
  type: 'setSorting'
  payload: { sorting: VirtuosoColumnSorting | null; resetPaging: boolean }
}

type SetPagingAction = {
  type: 'setPaging'
  payload: {
    nextPageIndex: number
    hasMorePages: boolean
    lastItemIndex: number
  }
}

type AddItemsAction<T> = {
  type: 'addItems'
  payload: { items: T[]; hasMorePages: boolean; pageIndex?: number }
}

type AddItemAction<T> = {
  type: 'addItem'
  payload: T
}

type DeleteItemAction = {
  type: 'deleteItem'
  payload: string
}

type ReplaceItemsAction<T> = {
  type: 'replaceItems'
  payload: T[]
}

type FilterAndShortItemsAction = {
  type: 'filterAndSortItems'
}

type SetForceRefreshAction = {
  type: 'setForceRefresh'
  payload: boolean
}

export type PageAction<T> =
  | FilterBarAction
  | SetErrorAction
  | SetSortingAction
  | SetPagingAction
  | AddItemsAction<T>
  | AddItemAction<T>
  | DeleteItemAction
  | ReplaceItemsAction<T>
  | FilterAndShortItemsAction
  | SetForceRefreshAction

export const ReducerHelpers = {
  resetPaging: <T,>(state: PageState<T>) => {
    return {
      ...state,
      pageIndex: 0,
      hasMorePages: true,
      lastItemIndex: 0,
      items: [],
      filteredItems: state.filteredItems ? [] : undefined,
    } as unknown
  },
}

export class PageReducer<T> {
  reducer(state: PageState<T>, action: PageAction<T>): PageState<T> {
    const mergeSuppliers = () => {
      const wereAllSuppliersSelected =
        state.selectedSupplierIds.length ===
        Object.keys(state.availableSuppliers).length

      // Merge internal and system suppliers
      if (
        state.selectedClient?.clientId &&
        state.availableSystemSuppliers &&
        state.availableInternalSuppliers &&
        state.availableInternalSuppliers[state.selectedClient.clientId]
      ) {
        state = {
          ...state,
          availableSuppliers: {
            ...state.availableSystemSuppliers,
            ...state.availableInternalSuppliers[state.selectedClient.clientId],
          },
        }
      } else if (state.availableSystemSuppliers) {
        state = {
          ...state,
          availableSuppliers: { ...state.availableSystemSuppliers },
        }
      }

      // If there are any selected suppliers that are not available, remove them
      state = {
        ...state,
        selectedSupplierIds: state.selectedSupplierIds.filter(
          (id) => state.availableSuppliers[id]
        ),
      }

      // If nothing is selected or all suppliers were previously selected, select all suppliers
      const areAllSuppliersSelected =
        state.selectedSupplierIds.length ===
        Object.keys(state.availableSuppliers).length
      if (
        !state.selectedSupplierIds.length ||
        (wereAllSuppliersSelected && !areAllSuppliersSelected)
      ) {
        state = {
          ...state,
          selectedSupplierIds: Object.keys(state.availableSuppliers),
        }
      }
    }

    const resetPaging = () => {
      state = ReducerHelpers.resetPaging(state) as PageState<T>
    }

    const filterAndSort = () => {
      if (state.filterAndSort) {
        state = {
          ...state,
          filteredItems: state.filterAndSort(state.items, state),
        }
      }
    }

    // If the reducer is invoked, isRestoredCache should be reset
    state = { ...state, isRestoredCache: false }

    switch (action.type) {
      case 'setAvailableInternalSuppliers':
        state = { ...state, availableInternalSuppliers: action.payload }
        mergeSuppliers()
        break
      case 'setAvailableSystemSuppliers':
        state = { ...state, availableSystemSuppliers: action.payload }
        mergeSuppliers()
        break
      case 'setSelectedClient':
        if (!isEqual(state.selectedClient, action.payload)) {
          state = { ...state, selectedClient: action.payload }
          mergeSuppliers()
          resetPaging()
        }
        break
      case 'setSearchField':
        state = { ...state, searchText: action.payload.text }
        if (action.payload.resetPaging) {
          resetPaging()
        }
        break
      case 'setDates':
        state = {
          ...state,
          dates: action.payload,
        }
        resetPaging()
        break
      case 'setSelectedSupplierIds':
        state = { ...state, selectedSupplierIds: [...action.payload] }
        resetPaging()
        break
      case 'setError':
        state = { ...state, error: action.payload }
        break
      case 'setSorting':
        if (action.payload.resetPaging) {
          resetPaging()
        }
        state = {
          ...state,
          sorting: action.payload.sorting,
        }
        break
      case 'setPaging':
        state = {
          ...state,
          pageIndex: action.payload.nextPageIndex,
          hasMorePages: action.payload.hasMorePages,
          lastItemIndex: action.payload.lastItemIndex,
        }
        break
      case 'addItems':
        if ((action.payload.pageIndex ?? 0) === 0) {
          state = {
            ...state,
            items: action.payload.items,
            hasMorePages: action.payload.hasMorePages,
          }
        } else if (
          action.payload.pageIndex != null &&
          state.pageSize != null &&
          action.payload.pageIndex * state.pageIndex < state.items.length
        ) {
          // if the existing number of items is more than the incoming page index * page size, it means we received the pages in the wrong order from the BE
          // and we need to insert the items in their correct place
          state = {
            ...state,
            items: [
              // Items before the page index we are inserting
              ...state.items.slice(
                0,
                action.payload.pageIndex * state.pageSize
              ),
              // These items
              ...action.payload.items,
              // Items after the page index we are inserting
              ...state.items.slice(action.payload.pageIndex * state.pageSize),
            ],
          }
        } else {
          state = {
            ...state,
            items: [...state.items, ...action.payload.items],
            hasMorePages: action.payload.hasMorePages,
          }
        }

        filterAndSort()
        break
      case 'addItem':
        state = { ...state, items: [action.payload, ...state.items] }
        filterAndSort()
        break
      case 'deleteItem':
        state = {
          ...state,
          items: state.items.filter(
            (i) => state.getItemId(i) !== action.payload
          ),
        }
        filterAndSort()
        break
      case 'replaceItems':
        state = {
          ...state,
          items: state.items.map((i) => {
            const itemToReplaceWith = action.payload.find(
              (r) => state.getItemId(r) === state.getItemId(i)
            )
            return itemToReplaceWith ?? i
          }),
        }
        filterAndSort()
        break
      case 'filterAndSortItems':
        filterAndSort()
        break
      case 'setForceRefresh':
        state = { ...state, refreshRandom: Math.random() }
        if (action.payload) {
          resetPaging()
        }
        break
    }

    return state
  }
}

export const usePageState = <T,>(
  pageReducer: PageReducer<T>,
  recoilPageState: RecoilState<PageState<T> | null>,
  recoilTableState: RecoilState<{
    endlessScrolling: EndlessScrollingArgs | null
    firstItemIndex: number
  }>,
  recoilLoadingAbortedState: RecoilState<boolean>,
  getItemId: (item: T) => T[keyof T] | null,
  defaultSorting?: VirtuosoColumnSorting,
  dateRangePresetKey?: string,
  additionalStateInit?: any,
  filterAndSort?: (items: T[], state?: unknown) => T[],
  pageSize?: number
) => {
  const getDatePreset = () => {
    if (!dateRangePresetKey) return [null, null]
    const preset = localStorage.getItem(dateRangePresetKey)
    const { dateFrom, dateTo } = calculateDatesFromPreset(
      preset as DatePickerSelectionPreset
    )
    return [dateFrom, dateTo]
  }
  const [pageStateCache, setPageStateCache] = useRecoilState(recoilPageState)
  const [virtuosoTableState, setVirtuosoTableState] =
    useRecoilState(recoilTableState)
  const [loadingAbortedState, setLoadingAbortedState] = useRecoilState(
    recoilLoadingAbortedState
  )
  const availableInternalSuppliers = useRecoilValue(userAvailableSuppliersState)
  const selectedClient = useRecoilValue(selectedClientState)
  const currentUser = useRecoilValue(stockrxUserState)
  const { suppliersIdDisplayNameDictionary } = useSuppliersUniquePerId()

  const [state, dispatch] = useReducer(
    pageReducer.reducer,
    pageStateCache
      ? { ...pageStateCache, isRestoredCache: true }
      : {
          getItemId,
          availableInternalSuppliers: availableInternalSuppliers,
          availableSystemSuppliers: null,
          availableSuppliers: {},
          items: [],
          selectedClient: null,
          pageIndex: 0,
          pageSize,
          hasMorePages: true,
          lastItemIndex: 0,
          searchText: '',
          dates: getDatePreset(),
          selectedSupplierIds: [],
          sorting: defaultSorting,
          error: null,
          forceRefresh: false,
          filterAndSort,
          ...additionalStateInit,
        }
  )

  useEffect(() => {
    if (
      !isEqual(
        pageStateCache?.availableInternalSuppliers,
        availableInternalSuppliers
      )
    ) {
      dispatch({
        type: 'setAvailableInternalSuppliers',
        payload: availableInternalSuppliers,
      })
    }
  }, [availableInternalSuppliers, pageStateCache?.availableInternalSuppliers])

  useEffect(() => {
    if (
      selectedClient &&
      !isEqual(selectedClient, pageStateCache?.selectedClient)
    ) {
      dispatch({
        type: 'setSelectedClient',
        payload: selectedClient,
      })
    }
  }, [selectedClient, pageStateCache?.selectedClient])

  useEffect(() => {
    if (
      Object.keys(suppliersIdDisplayNameDictionary).length > 0 &&
      !isEqual(
        suppliersIdDisplayNameDictionary,
        pageStateCache?.availableSystemSuppliers
      )
    ) {
      dispatch({
        type: 'setAvailableSystemSuppliers',
        payload: suppliersIdDisplayNameDictionary,
      })
    }
  }, [
    suppliersIdDisplayNameDictionary,
    pageStateCache?.availableSystemSuppliers,
  ])

  useEffect(() => {
    setPageStateCache(state)
  }, [setPageStateCache, state])

  return {
    state,
    dispatch,
    virtuosoTableState,
    setLoadingAbortedState,
    shouldRunLoadingEffect:
      (!state.isRestoredCache || loadingAbortedState) &&
      state.selectedClient &&
      state.selectedSupplierIds.length > 0 &&
      state.hasMorePages,
    odsToPharmacyNameMappings: currentUser?.odsToPharmacyNameMappings ?? null,
    virtuosoTableHelpers: {
      handleInfiniteLoading: (lastItemIndex: number) => {
        if (lastItemIndex > state.lastItemIndex && state.hasMorePages) {
          setTimeout(() => {
            dispatch({
              type: 'setPaging',
              payload: {
                nextPageIndex: state.pageIndex + 1,
                hasMorePages: state.hasMorePages,
                lastItemIndex: lastItemIndex,
              },
            })
          })
        }
      },
      handleSortingChanged: (
        sorting: VirtuosoColumnSorting | null,
        resetPaging?: boolean
      ) => {
        dispatch({
          type: 'setSorting',
          payload: { sorting, resetPaging: resetPaging ?? true },
        })
      },
      handleRangeChanged: (rangeFirstItemIndex: number) => {
        if (virtuosoTableState.firstItemIndex !== rangeFirstItemIndex) {
          setVirtuosoTableState({
            endlessScrolling: virtuosoTableState.endlessScrolling,
            firstItemIndex: rangeFirstItemIndex,
          })
        }
      },
    },
  }
}

export const useResetRecoilPageState = (pageId: string) => {
  const {
    pageState,
    tableState,
    loadingAbortedState: abortLoadingState,
  } = recoilPageStateAtomsMap[pageId]

  const resetPageStateCache = useResetRecoilState(pageState)
  const resetVirtuosoTableState = useResetRecoilState(tableState)
  const resetAbortLoadingState = useResetRecoilState(abortLoadingState)

  return {
    resetPageStateCache,
    resetVirtuosoTableState,
    resetAbortLoadingState,
  }
}

const recoilPageStateAtomsMap = {} as {
  [key: string]: {
    pageState: RecoilState<any>
    tableState: RecoilState<any>
    loadingAbortedState: RecoilState<boolean>
  }
}

export const createRecoilPageState = <T,>(pageId: string) => {
  const recoilPageState = atom<PageState<T> | null>({
    key: `${pageId}-page-cache`,
    default: null,
  })

  const recoilTableState = atom<{
    endlessScrolling: EndlessScrollingArgs | null
    firstItemIndex: number
  }>({
    key: `${pageId}-virtuoso-table-cache`,
    default: {
      endlessScrolling: null,
      firstItemIndex: 0,
    },
  })

  const recoilLoadingAbortedState = atom<boolean>({
    key: `${pageId}-loading-aborted`,
    default: false,
  })

  recoilPageStateAtomsMap[pageId] = {
    pageState: recoilPageState,
    tableState: recoilTableState,
    loadingAbortedState: recoilLoadingAbortedState,
  }

  return {
    recoilPageState,
    recoilTableState,
    recoilLoadingAbortedState,
  }
}
