import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import { FC, useReducer, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { DateTime } from 'luxon'
import TextField from '@mui/material/TextField'
import { locale } from '../../locales'
import theme from '../../styles/theme'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const translation = locale.translation.DateRangePickers

export type DatePickerSelectionPreset =
  | 'lastThirtyDays'
  | 'monthToDate'
  | 'today'
  | 'yesterday'
  | 'anyDate'

interface ComponentState {
  dateFrom: DateTime | null
  dateTo: DateTime | null
  lastClickedPreset: DatePickerSelectionPreset | null
}

interface Action {
  type: 'updateDateFrom' | 'updateDateTo' | 'setDatesPreset' | 'clearSelection'
  payload: DateTime | null | DatePickerSelectionPreset
}

export const calculateDatesFromPreset = (
  preset: DatePickerSelectionPreset | null
) => {
  switch (preset) {
    case 'monthToDate':
      return {
        dateFrom: DateTime.now().startOf('month'),
        dateTo: DateTime.now().endOf('day'),
      }
    case 'yesterday':
      return {
        dateFrom: DateTime.now().minus({ days: 1 }).startOf('day'),
        dateTo: DateTime.now().minus({ days: 1 }).endOf('day'),
      }
    case 'today':
      return {
        dateFrom: DateTime.now().startOf('day'),
        dateTo: DateTime.now().endOf('day'),
      }
    case 'anyDate':
      return {
        dateFrom: null,
        dateTo: null,
      }
    default:
      return {
        dateFrom: DateTime.now().startOf('day').minus({ days: 30 }),
        dateTo: DateTime.now().endOf('day'),
      }
  }
}

const reducer = (state: ComponentState, action: Action): ComponentState => {
  switch (action.type) {
    case 'updateDateFrom':
      return { ...state, dateFrom: action.payload as DateTime }
    case 'updateDateTo':
      return { ...state, dateTo: action.payload as DateTime }
    case 'clearSelection':
      return { ...state, dateFrom: null, dateTo: null }
    case 'setDatesPreset':
      return {
        ...state,
        ...calculateDatesFromPreset(
          action.payload as DatePickerSelectionPreset
        ),
        lastClickedPreset: action.payload as DatePickerSelectionPreset,
      }
  }
}

interface DateRangePickerPopoverProps {
  dateFrom: DateTime | null
  dateTo: DateTime | null
  localStorageKey?: string
  allowNullDates?: Boolean
  onDatesSelected: (
    from: DateTime | null,
    to: DateTime | null,
    lastClickedPreset?: DatePickerSelectionPreset | null
  ) => void
  disabled?: boolean
  maxDate?: DateTime | null
  showClearButton?: boolean
}

const DateRangePickerPopover: FC<DateRangePickerPopoverProps> = ({
  dateFrom,
  dateTo,
  onDatesSelected,
  localStorageKey,
  allowNullDates,
  disabled,
  maxDate,
  showClearButton,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [state, dispatch] = useReducer(reducer, {
    dateFrom: dateFrom,
    dateTo: dateTo,
    lastClickedPreset: null,
  })

  const handleDatesPopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }
  const handleDatesPopoverClose = () => {
    setAnchorEl(null)
  }

  const popOverOpen = Boolean(anchorEl)
  const popOverId = popOverOpen ? 'popover-el' : undefined
  return (
    <>
      <Button
        data-testid="date-range-picker-button"
        variant={!state.dateFrom && !state.dateTo ? 'outlined' : 'contained'}
        onClick={handleDatesPopoverClick}
        sx={{
          display: 'flex',
          gap: theme.spacing(1),
        }}
        disabled={disabled}
      >
        <span>
          {dateFrom?.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) ??
            state.dateFrom?.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) ??
            translation.NullDate}
        </span>
        <span>{translation.To}</span>
        <span>
          {dateTo?.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) ??
            state.dateTo?.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) ??
            translation.NullDate}
        </span>
      </Button>
      <Popover
        id={popOverId}
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={handleDatesPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={3}
      >
        <Stack
          direction={'row'}
          sx={{
            paddingTop: theme.spacing(2),
            paddingX: theme.spacing(3),
            marginLeft: `-${theme.spacing(1)}`,
          }}
        >
          {allowNullDates && (
            <Button
              data-testid="date-range-picker-clear-selection-button"
              onClick={() => {
                dispatch({ type: 'clearSelection', payload: null })
                dispatch({ type: 'setDatesPreset', payload: 'anyDate' })
                if (localStorageKey) {
                  localStorage.setItem(localStorageKey, 'anyDate')
                }
              }}
            >
              {translation.ClearSelection}
            </Button>
          )}
          <Button
            data-testid="date-range-picker-last-thirty-days-button"
            onClick={() => {
              dispatch({ type: 'setDatesPreset', payload: 'lastThirtyDays' })
            }}
          >
            {translation.LastThirtyDays}
          </Button>
          <Button
            data-testid="date-range-picker-month-month-to-date-button"
            onClick={() => {
              dispatch({ type: 'setDatesPreset', payload: 'monthToDate' })
            }}
          >
            {translation.MonthToDate}
          </Button>
          <Button
            data-testid="date-range-picker-yesterday-button"
            onClick={() => {
              dispatch({ type: 'setDatesPreset', payload: 'yesterday' })
            }}
          >
            {translation.Yesterday}
          </Button>
          <Button
            data-testid="date-range-picker-today-button"
            onClick={() => {
              dispatch({ type: 'setDatesPreset', payload: 'today' })
            }}
          >
            {translation.Today}
          </Button>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: theme.spacing(1),
            }}
          >
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <Box
                data-testid="date-range-picker-date-from-container"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  variant="overline"
                  sx={{
                    padding: theme.spacing(3),
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginBottom: `-${theme.spacing(3)}`,
                  }}
                >
                  {translation.From}
                </Typography>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={state.dateFrom}
                  onChange={(newDate) =>
                    dispatch({ type: 'updateDateFrom', payload: newDate })
                  }
                  renderInput={(params: any) => <TextField {...params} />}
                  maxDate={
                    maxDate ??
                    DateTime.fromMillis(
                      Math.min(
                        DateTime.now().toMillis(),
                        (state.dateTo ?? DateTime.now()).toMillis()
                      )
                    )
                  }
                  minDate={DateTime.fromISO('2022-01-01')}
                />
              </Box>
              <Box
                data-testid="date-range-picker-date-until-container"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  variant="overline"
                  sx={{
                    padding: theme.spacing(3),
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginBottom: `-${theme.spacing(3)}`,
                  }}
                >
                  {translation.Until}
                </Typography>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={state.dateTo}
                  minDate={state.dateFrom}
                  maxDate={maxDate ?? DateTime.now()}
                  onChange={(newDate) =>
                    dispatch({ type: 'updateDateTo', payload: newDate })
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingX: theme.spacing(2),
              paddingBottom: theme.spacing(3),
              paddingTop: 0,
            }}
          >
            {showClearButton && (
              <Button
                data-testid="date-range-picker-clear-button"
                variant="contained"
                size="large"
                disableElevation={true}
                sx={{
                  display: 'flex',
                  gap: theme.spacing(1),
                }}
                onClick={() => {
                  dispatch({ type: 'clearSelection', payload: null })
                  dispatch({ type: 'setDatesPreset', payload: 'anyDate' })
                  if (localStorageKey) {
                    localStorage.setItem(localStorageKey, 'anyDate')
                  }
                }}
              >
                {translation.Clear}
              </Button>
            )}
            <Button
              data-testid="date-range-picker-apply-button"
              variant="contained"
              size="large"
              disableElevation={true}
              sx={{
                display: 'flex',
                gap: theme.spacing(1),
              }}
              onClick={() => {
                if (state.dateFrom && state.dateTo) {
                  if (localStorageKey && state.lastClickedPreset) {
                    localStorage.setItem(
                      localStorageKey,
                      state.lastClickedPreset.toString()
                    )
                  }
                  onDatesSelected(
                    state.dateFrom,
                    state.dateTo,
                    state.lastClickedPreset
                  )
                  handleDatesPopoverClose()
                } else if (allowNullDates || showClearButton) {
                  onDatesSelected(state.dateFrom, state.dateTo)
                  handleDatesPopoverClose()
                }
              }}
            >
              <span>
                {state.dateFrom?.toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY
                ) ?? translation.NullDate}
              </span>
              <span>{translation.To}</span>
              <span>
                {state.dateTo?.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) ??
                  translation.NullDate}
              </span>
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  )
}

export default DateRangePickerPopover
