import {
  PageAction,
  PageReducer,
  PageState,
  ReducerHelpers,
} from '../PageState'
import { BookInSummaryWithOdsCode } from './entities/BookInSummary'

interface SelectedProduct {
  productId: string
  packSize: number | null
  productName: string
  unitOfMeasure: string | null
  subPackDescription: string | null
  amppId: string | null
  hasAmpp?: boolean
}

export type SetSelectedProductAction = {
  type: 'setSelectedProduct'
  payload: SelectedProduct | null
}

export type SetShowReEditsOnlyAction = {
  type: 'setShowReEditsOnly'
  payload: boolean
}

export interface BookInSummaryWithProductSelectionPageState
  extends PageState<BookInSummaryWithOdsCode> {
  selectedProduct: SelectedProduct | null
  showReEditsOnly: boolean
}

export type BookInSummaryWithProductSelectionPageAction =
  | PageAction<BookInSummaryWithOdsCode>
  | SetSelectedProductAction
  | SetShowReEditsOnlyAction

export class BookInSummaryWithProductSelectionPageReducer extends PageReducer<BookInSummaryWithOdsCode> {
  reducer(
    state: BookInSummaryWithProductSelectionPageState,
    action: BookInSummaryWithProductSelectionPageAction
  ): BookInSummaryWithProductSelectionPageState {
    state = {
      ...(super.reducer(
        state,
        action as PageAction<BookInSummaryWithOdsCode>
      ) as BookInSummaryWithProductSelectionPageState),
    }

    switch (action.type) {
      case 'setSelectedProduct':
        state = {
          ...(ReducerHelpers.resetPaging(
            state as PageState<BookInSummaryWithOdsCode>
          ) as BookInSummaryWithProductSelectionPageState),
          selectedProduct: action.payload,
        }
        break
      case 'setShowReEditsOnly':
        state = {
          ...(ReducerHelpers.resetPaging(
            state as PageState<BookInSummaryWithOdsCode>
          ) as BookInSummaryWithProductSelectionPageState),
          showReEditsOnly: action.payload,
        }
        break
    }

    return state
  }
}
