import { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { locale } from '../../locales'
import { useRecoilState } from 'recoil'
import Box from '@mui/material/Box'
import theme from '../../styles/theme'
import { Alert, Button, CircularProgress } from '@mui/material'
import { Done, Error } from '@mui/icons-material'
import PriceFileUploadPopup from './components/PriceFileUploadPopup'
import {
  getLocaleDateMedWithWeekDay,
  getLocaleDateTimeMedWithoutWeekDay,
} from '../../utils/Helpers'
import { ServiceContext } from '../../providers/ServicesProvider'
import { webSocketLastMessageState } from '../../state/WebSocketState'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import { ClientTypes } from '../../types/entities/ClientPermission'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import {
  PriceFileUploadsStates,
  RecoilPageIds,
  WebSocketActions,
} from '../../constants'
import {
  PriceFileWebSocketMessage,
  UploadedPriceFile,
} from './price-file-entities'
import PriceFileDetailsPopup from './components/PriceFileDetailsPopup'
import VirtuosoMuiTable, {
  VirtuosoColumn,
} from '../../components/Data/VirtuosoMuiTable'
import {
  PageAction,
  PageReducer,
  PageState,
  createRecoilPageState,
  usePageState,
} from '../PageState'
import { sortArrayByPropertyKey } from '../../utils/SortingUtils'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import { useAbortableRequest } from '../../hooks/useAbortableRequest'
import MultiSelection from '../../components/Forms/MultiSelection'

const translation = locale.translation.PriceFilesPage.TabUploads
const columnNames = translation.TableColumnTitles

interface PriceFilesPageState extends PageState<UploadedPriceFile> {}
type PriceFilesPageAction = PageAction<UploadedPriceFile>

const filterAndSort = (items: UploadedPriceFile[], state?: unknown) => {
  const sortState = (state as PageState<UploadedPriceFile>)?.sorting
  const filteredItems = [...items]
  if (sortState) {
    const sortingPropertyKey =
      sortState.dataKey === 'priceStartDate'
        ? 'pricesEndDate'
        : sortState.dataKey

    sortArrayByPropertyKey(filteredItems, {
      sortingType: sortState.sortingType,
      sortingPropertyKey: sortingPropertyKey,
    })
  }
  return filteredItems
}

class PriceFilesPageReducer extends PageReducer<UploadedPriceFile> {
  reducer(
    state: PriceFilesPageState,
    action: PriceFilesPageAction
  ): PriceFilesPageState {
    const updateFilteredItems = () => {
      state = {
        ...state,
        filteredItems: filterAndSort(state.items, state),
      }
    }
    state = {
      ...(super.reducer(
        state,
        action as PageAction<UploadedPriceFile>
      ) as PriceFilesPageState),
    }

    switch (action.type) {
      case 'setSorting':
        state = {
          ...state,
          sorting: action.payload.sorting,
        }
        break
    }
    updateFilteredItems()
    return state
  }
}
// Create static objects for the recoil state and reducer
const { recoilPageState, recoilTableState, recoilLoadingAbortedState } =
  createRecoilPageState<UploadedPriceFile>(RecoilPageIds.TabPriceFiles)
const pageReducer = new PriceFilesPageReducer()

const statusCellNode = (v: unknown, rowData: UploadedPriceFile): ReactNode => {
  const getInfoBox = () => {
    switch (v) {
      case PriceFileUploadsStates.Processed:
        return translation.ItemProcessedSuccessfully
      case PriceFileUploadsStates.Failed:
        return translation.ItemError(rowData.failedReason)
      default:
        return translation.ItemProcessing
    }
  }
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      title={getInfoBox()}
    >
      {v === PriceFileUploadsStates.Processed && (
        <Done
          titleAccess={translation.ItemProcessedSuccessfully}
          color="success"
        />
      )}
      {v === PriceFileUploadsStates.Failed && <Error color="error" />}
      {(v === PriceFileUploadsStates.Processing ||
        v === PriceFileUploadsStates.Uploaded ||
        v === PriceFileUploadsStates.Deleting) && (
        <CircularProgress size={'24px'} />
      )}
    </Box>
  )
}

const getColumnDefinitions = (suppliers: {
  [key: string]: string
}): VirtuosoColumn<UploadedPriceFile>[] => {
  const columnDefinitions = [
    {
      label: columnNames[0],
      dataKey: 'fileOriginalName',
      widthCss: '40%',
    },
    {
      label: columnNames[1],
      dataKey: 'supplierId',
      valueTransformer: (rowData) => {
        return suppliers[rowData.supplierId]
      },
      widthCss: '10%',
    },
    {
      label: columnNames[2],
      dataKey: 'uploadedAt',
      valueTransformer: (rowData) => {
        return getLocaleDateTimeMedWithoutWeekDay(rowData.uploadedAt)
      },
      sortable: true,
      widthCss: '15%',
    },
    {
      label: columnNames[3],
      dataKey: 'priceStartDate',
      valueTransformer: (rowData) => {
        return `${getLocaleDateMedWithWeekDay(rowData.pricesStartDate)} - ${
          Date.parse(rowData.pricesEndDate) === 253402214400000 // 9999-12-31
            ? translation.Ongoing
            : getLocaleDateMedWithWeekDay(rowData.pricesEndDate)
        }`
      },
      widthCss: '30%',
      sortable: true,
    },
    {
      label: columnNames[4],
      dataKey: 'currentState',
      customCellNode: statusCellNode,
      widthCss: '72px',
    },
  ] as VirtuosoColumn<UploadedPriceFile>[]
  return columnDefinitions
}

const TabPriceFiles: FC = () => {
  const [showUploadDialog, setShowUploadDialog] = useState(false)
  const { platformHttpService } = useContext(ServiceContext)
  const [selectedPriceFile, setSelectedPriceFile] =
    useState<UploadedPriceFile | null>(null)

  const [webSocketLastMessage, setWebSocketLastMessage] = useRecoilState(
    webSocketLastMessageState
  )

  const { setIsLoading } = useGlobalIsLoading()

  // Handle page state for this page
  const {
    state: genericPageState,
    dispatch: genericDispatch,
    virtuosoTableState,
    shouldRunLoadingEffect,
    virtuosoTableHelpers,
    setLoadingAbortedState,
  } = usePageState<UploadedPriceFile>(
    pageReducer,
    recoilPageState,
    recoilTableState,
    recoilLoadingAbortedState,
    (item) => item.uploadId,
    {
      dataKey: 'uploadedAt',
      sortingType: 'DESC',
    },
    '',
    {}
  )
  const state = genericPageState as PriceFilesPageState
  const dispatch = genericDispatch as React.Dispatch<PriceFilesPageAction>

  const { abortControllerRef, startAbortableRequest, finishAbortableRequest } =
    useAbortableRequest(setLoadingAbortedState)

  const columnDefinitions = getColumnDefinitions(state.availableSuppliers)

  useEffect(() => {
    const getAllPriceFiles = async () => {
      return await platformHttpService.postAsync<Array<UploadedPriceFile>>(
        PlatformApiPaths.GetAllUploadedPriceFiles(
          state.selectedClient!.clientId
        ),
        {
          supplierIds: state.selectedSupplierIds,
        },
        'SuppliersPricesBaseUrl',
        abortControllerRef.current?.signal
      )
    }
    if (
      shouldRunLoadingEffect &&
      state.selectedClient!.clientType === ClientTypes.Company
    ) {
      // every time a new request comes in, cancel the previous one
      startAbortableRequest()
      dispatch({ type: 'setError', payload: null })

      getAllPriceFiles().then((response) => {
        // Check if the request was cancelled
        if (finishAbortableRequest(response.wasCancelled)) {
          return
        }
        // Process response
        if (response?.data && !response.hasErrors) {
          dispatch({
            type: 'addItems',
            payload: { items: response.data, hasMorePages: false },
          })
        } else {
          dispatch({
            type: 'setError',
            payload: GetErrorMessage(response?.statusCode),
          })
        }
      })
    }
  }, [
    platformHttpService,
    state.selectedClient,
    dispatch,
    shouldRunLoadingEffect,
    abortControllerRef,
    startAbortableRequest,
    finishAbortableRequest,
    state.selectedSupplierIds,
  ])

  useEffect(() => {
    if (
      state.selectedClient?.clientId &&
      (webSocketLastMessage?.action === WebSocketActions.PriceFileProcessed ||
        webSocketLastMessage?.action === WebSocketActions.PriceFileDeleted)
    ) {
      const { uploadId, clientId } =
        webSocketLastMessage as PriceFileWebSocketMessage

      if (clientId === state.selectedClient.clientId && uploadId) {
        switch (webSocketLastMessage?.action) {
          case WebSocketActions.PriceFileProcessed:
            const getPriceFileUploadsRequest =
              platformHttpService.getAsync<UploadedPriceFile>(
                PlatformApiPaths.GetUploadedPriceFile(
                  state.selectedClient.clientId,
                  uploadId
                ),
                'SuppliersPricesBaseUrl'
              )
            setIsLoading(false)
            getPriceFileUploadsRequest.then((response) => {
              setWebSocketLastMessage(null)
              if (response.data && !response.hasErrors) {
                const updatedPriceFileUpload = response.data
                const existingItem = state.items.find(
                  (p) => p.uploadId === uploadId
                )
                if (existingItem) {
                  dispatch({
                    type: 'replaceItems',
                    payload: [updatedPriceFileUpload],
                  })
                } else {
                  dispatch({
                    type: 'addItem',
                    payload: updatedPriceFileUpload,
                  })
                }
              } else {
                dispatch({
                  type: 'setError',
                  payload: GetErrorMessage(response.statusCode),
                })
              }
            })
            break
          case WebSocketActions.PriceFileDeleted:
            setWebSocketLastMessage(null)
            dispatch({
              type: 'deleteItem',
              payload: uploadId,
            })
            break
        }
      }
    }
  }, [
    dispatch,
    platformHttpService,
    setIsLoading,
    setWebSocketLastMessage,
    state.items,
    state.selectedClient,
    webSocketLastMessage,
  ])

  const handleCloseDetails = (wasDeleted: boolean) => {
    if (wasDeleted && selectedPriceFile) {
      selectedPriceFile.currentState = PriceFileUploadsStates.Deleting
      dispatch({ type: 'replaceItems', payload: [selectedPriceFile] })
    }
    setSelectedPriceFile(null)
  }

  const handleUploadSuccess = (newPriceFile: UploadedPriceFile) => {
    setShowUploadDialog(false)
    dispatch({ type: 'addItem', payload: newPriceFile })
  }

  return (
    state.selectedClient && (
      <Box
        sx={{
          paddingY: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: theme.spacing(2),
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => setShowUploadDialog(true)}
            data-testid="show-price-file-upload-button"
          >
            {translation.UploadButton}
          </Button>
          {showUploadDialog && (
            <PriceFileUploadPopup
              selectedClient={state.selectedClient}
              onClose={() => setShowUploadDialog(false)}
              onProcessedSuccess={handleUploadSuccess}
            />
          )}
          <MultiSelection
            id={'multi-supplier'}
            onSelectionApplied={(s) => {
              dispatch({
                type: 'setSelectedSupplierIds',
                payload: s,
              })
            }}
            options={state.availableSuppliers}
            selectedOptionKeys={state.selectedSupplierIds}
            title={
              state.selectedSupplierIds.length > 0 &&
              state.selectedSupplierIds.length ===
                Object.entries(state.availableSuppliers).length
                ? locale.translation.MultiSupplierSelection.AllSuppliers
                : locale.translation.MultiSupplierSelection.SpecificSuppliers
            }
            isDirty={
              state.selectedSupplierIds.length > 0 &&
              state.selectedSupplierIds.length !==
                Object.entries(state.availableSuppliers).length
            }
          />
        </Box>
        {!state.error && state.filteredItems && (
          <VirtuosoMuiTable
            rows={state.filteredItems}
            columns={columnDefinitions}
            onRowClick={(row) => {
              setSelectedPriceFile(row)
            }}
            sorting={state.sorting}
            onSortingChanged={(sorting) => {
              virtuosoTableHelpers.handleSortingChanged(sorting, false)
            }}
            initialFirstItemIndex={virtuosoTableState.firstItemIndex}
            onRangeChanged={virtuosoTableHelpers.handleRangeChanged}
          />
        )}
        {state.error && (
          <Alert variant="filled" severity="error">
            {state.error}
          </Alert>
        )}
        {selectedPriceFile && (
          <PriceFileDetailsPopup
            selectedPriceFile={selectedPriceFile}
            selectedClient={state.selectedClient}
            onClosed={handleCloseDetails}
            open={true}
          />
        )}
      </Box>
    )
  )
}

export default TabPriceFiles
