import { useContext, useEffect } from 'react'
import { ServiceContext } from '../providers/ServicesProvider'
import { PresignedUrlResponse } from '../pages/GoodsIn/entities/BookIn'

function usePresignedBase64Image(
  presignUrl: string,
  startDownloading: boolean,
  onDownloaded: (base64Image: string) => void,
  onError: () => void
) {
  const { platformHttpService } = useContext(ServiceContext)
  useEffect(() => {
    if (startDownloading) {
      platformHttpService
        .getAsync<PresignedUrlResponse>(presignUrl)
        .then((response) => fetch(response.data!.presignedUrl))
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const base64data = reader.result
            onDownloaded(base64data as string)
          }
          reader.readAsDataURL(blob)
        })
        .catch((error) => {
          onError()
        })
    }
  }, [onDownloaded, onError, platformHttpService, presignUrl, startDownloading])
}

export default usePresignedBase64Image
